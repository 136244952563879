<template>
  <div class="wrap">
    <Loading v-show="$store.state.isLoading"/>
    <div v-show="!$store.state.isLoading">
      <van-form input-align="right" error-message-align="right">
        <van-field
          readonly
          v-model="userName"
          name="userName"
          label="姓名"
        />
        <van-field
          v-if="columns.length =='1'"
          autosize
          readonly
          v-model="contractNum"
          name="contractNum"
          label="合同号"
        />
        <van-field
          v-if="columns.length !='1'"
          autosize
          readonly
          clickable
          name="contractNum"
          :value="contractNum"
          label="合同号"
          @click="showContractPicker = true"
          right-icon="arrow-down"
        />
        <van-popup v-model="showContractPicker" position="bottom">
          <van-picker
            show-toolbar
            :columns="columns"
            @confirm="onContractConfirm"
            @cancel="showContractPicker = false"
          />
        </van-popup>
        <van-field
          readonly
          v-model="vin"
          name="vin"
          label="车架号"
        />
        <van-field
          readonly
          clickable
          name="date"
          :value="date"
          label="结清日期"
          placeholder="请选择结清日期"
          @click="onDateClick"
          right-icon="arrow-down"
        />
        <van-calendar :show-confirm="isShowConbutton" :formatter="formatter" v-model="showCalendar" :max-date="maxDate" @confirm="onDateConfirm" />
        <div style="background-color: #fff;margin: 10px;padding:1px;">
          <div offset="1" style="color:#333333;font-size:20px;margin:10px;">还款金额：
            <span style="color:#E50012">{{moneyValue | formatMoney(2)}}</span>
          </div>
          <div class="mountWrap" v-if="conType=='huizu'">
            <div offset="1" style="color:#808080;font-size:15px;margin:10px;" v-if="parseInt(guazhang)>0">
              <span>还款金额已减去挂账金额</span>
              <span style="display:inline-block;margin:auto 5px;">{{guazhang | formatMoney(2)}}</span>
            </div>
            <van-cell title="未偿本金" :value="weichangValue" :border='false'/>
            <van-cell title="应收租息" :value="zuxiValue" :border='false' />
            <van-cell title="违约金" :value="weiyuValue" :border='false'/>
          </div>
          <!-- 直租不显示违约金 -->
          <!-- <div v-if="conType=='huizu'" offset="1" style="color:#808080;font-size:15px;margin:10px;">违约金：
            <span>{{weiyuValue}}</span>
          </div> -->
          <!-- <div style="text-align:right;padding:10px;">
            <van-button 
              color="#0091FF" 
              type="primary" 
              size="small" 
              round 
              plain
              style="padding:0 10px;"
              @click="applySubmit"
            >提前结清申请</van-button>
          </div> -->
        </div>
        <div class="tips">
            <p>温馨提示：</p>
            <p>1、请于当天15:00前申请，15:00后申请则会顺延到次日。 </p>
            <!-- <p>2、月底最后一天不允许提前结清申请</p> 10月份需求-20221014注释掉的-->
            <p>2、结清日期不能等于计划还款日</p>
        </div>
      </van-form>
      <van-col span="20" offset="2" style="position:fixed;bottom:10px;">
        <van-button round block type="danger" :disabled="isDisable" @click="applySubmit">
        提前结清申请
        </van-button>
      </van-col>
    </div>
  </div>
</template>
<script>
  import moment from 'moment';
  import {calculationDetail,calculation,getOpenId,settleApplicationDetail } from '@/server'
  import { Dialog,Toast} from 'vant';
  import {setCookie,getCookie,domainUrl} from '@/lib/utils';
  import { formatMoney } from '@/lib/format';
  import Loading from "@/components/Loading";
  var dateArr;
  export default {
    components: {
      Loading
    },
  data() {
    return {
      isDisable:true,
      cardId:this.$store.state.cardId,
      accessToken:this.$store.state.accessToken,
      // openId:localStorage.getItem("openId") ||this.$store.state.openId,
      openId:getCookie('openId'),
      code:this.$route.query.code || '',
      userName: '',
      contractNum: '',
      vin: '',
      date: '',
      moneyValue:'',//还款金额
      weichangValue:'',//未偿本金
      zuxiValue:'',//应收租息
      weiyuValue:'',//违约金
      guazhang:'',//挂账
      showContractPicker: false,
      showCalendar: false,
      columns: [],
      vins:[],
      dataInfo:[],
      conType:'',
      isYuQi:'1',//是否逾期，0:是，1否
      isYunXu:'1',//还款日变更后首次还款前是否可以允许提交提前结清申请，0:不允许，1:允许
      maxDate:new Date(),
      isShowConbutton:true//是否显示日历的确认按钮
    };
  }, 
  filters:{
    //格式化金额
    formatMoney(value, dot, digits){
      if(!value){
        return ''
      }else{
        return formatMoney(value, dot, digits)
      }
    },
  },
  mounted(){
    let that = this;
    sessionStorage.setItem('key', 'early1');
    window.addEventListener("popstate", function(e) {  //回调函数中实现需要的功能
    	if(sessionStorage.getItem('key') == 'early1') {
        that.$router.push({ path: '/personalCenter' });
      }
    }, false);
  },
  created(){
    this.$store.commit('backFlag', '');
    // this.$store.commit('backFlagNum', '');
    // sessionStorage.setItem('key', 0);
    //如果有openid
    if(this.openId){
      getOpenId({openid:this.openId}).then(res=>{
        if(res.wx_code=='0'){
          this.$store.commit('bindStatus', res.bind_status);
          localStorage.setItem("bindStatus", res.bind_status);
          localStorage.setItem("accessToken", res.access_token);
          this.$store.commit('accessToken', res.access_token);
         setCookie('openId',res.openid);
          this.$store.commit('openId', res.openid);
          this.$store.commit('cardId', res.card_id);
          localStorage.setItem("cardId", res.card_id);
          this.cardId = res.card_id;
          this.accessToken = res.access_token;
          this.openId = res.openid;
          if(res.bind_status !=='1'){
            this.$router.push({ path: '/login?redirect=/earlySettlementCalculation' });
          }else{
            this.getDetail();
          }
        }else{
          Toast.fail({
            message: res.msg,
            duration:'3000'
          });
        }
      })
    } else{
      if(!this.code) {
        this.$store.commit('bindStatus', '1');
        localStorage.setItem("bindStatus", '1');
        let uiCode = encodeURIComponent(domainUrl+'/earlySettlementCalculation');
        window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxe40be82e1238dfe7&redirect_uri='+uiCode+'&response_type=code&scope=snsapi_userinfo&state=STATE&connect_redirect=1#wechat_redirect';         
      }else{//获取code
        getOpenId({percode:this.code}).then(res=>{
          if(res.wx_code=='0'){
            this.$store.commit('bindStatus', res.bind_status);
            localStorage.setItem("bindStatus", res.bind_status);
            this.$store.commit('accessToken', res.access_token);
            localStorage.setItem("accessToken", res.access_token);
            this.$store.commit('openId', res.openid);
           setCookie('openId',res.openid);
            this.$store.commit('cardId', res.card_id);
            localStorage.setItem("cardId", res.card_id);
            this.cardId = res.card_id;
            this.accessToken = res.access_token;
            this.openId = res.openid;
            if(res.bind_status !=='1'){
              this.$router.push({ path: '/login?redirect=/earlySettlementCalculation' });
            }else{
              this.getDetail();
            }
          }else{
            Toast.fail({
              message: res.msg,
              duration:'3000'
            });
          }
        });
      }
    }  
  },
  methods: {
    getDetail(){
      this.isYuQi = '1';
      this.isYunXu = '1';
      let res = this.$store.state.dataListTotalCal;
      let nearPlanDate = res[0].near_plandate;
      nearPlanDate = moment(nearPlanDate).format('YYYY-M-D');
      dateArr = nearPlanDate.split('-');
      // dateArr = ['2021','3','5']
      // this.maxDate = new Date(dateArr[0], dateArr[1]-1, dateArr[2]);
      // const params = {
      // access_token:this.accessToken,
      // card_id:this.cardId
      // }
    //信息返显
      // calculationDetail(params).then(res=>{
      // if(res.wx_code == '0'){
        this.dataInfo = res;
        // this.userName = res.cus_name;
        var columns = [];
        var vins = [];
        res.map(item => {
          columns = [...columns,item.con_no]
        });
        res.map(item => {
          vins = [...vins,item.vin]
        });
        this.columns = columns;
        var data = res[0];
        this.contractNum = this.columns[0];
        this.vins = vins;
        this.vin = vins[0];
        this.userName = data.cus_name;
        this.conType = data.con_type;
        if(data.wx_code == '104'){
          this.isYuQi = '0';
          Dialog.alert({
            message: '您存在逾期租金，请及时还款'
          });
        }else if(data.wx_code == 'E105A'){
          this.isYunXu = '0';
        }else{
          this.maxDate = new Date(dateArr[0], dateArr[1]-1, dateArr[2]);
        }
        
      // }
      // })
    },
    getLastDay(year, month){
      var d = new Date(0);
      if (month == 12){
        d.setUTCFullYear(year + 1);
        d.setUTCMonth(0);
      }else{
        d.setUTCFullYear(year);
        d.setUTCMonth(month);
      }
      d.setTime(d.getTime() - 1);
      return d.getUTCDate();
    },
    formatter(day) {
      this.isShowConbutton = true;
      var myDate = new Date();
      const year = day.date.getFullYear();
      const month = day.date.getMonth()+1;
      const date = day.date.getDate();
      const lastDay = this.getLastDay(year,month);
      //最后一天不可选
      // if (date === lastDay) {10月份需求-20221014注释掉的
      //   day.type = 'disabled'
      // }
      //还款日当天不可选
      if (year == dateArr[0] && month == dateArr[1] && date == dateArr[2]) {
        day.type = 'disabled'
      }
      //还款日是今天的话，今天也不可选
      if(myDate.getFullYear() == dateArr[0] && myDate.getMonth()+1 == dateArr[1] && myDate.getDate() == dateArr[2]){
        day.type = 'disabled';
        this.isShowConbutton = false;
      }
      return day;
    },
    onDateClick(){
      if(this.isYuQi=='0'){
        this.showCalendar = false;
        Dialog.alert({
          message: '您存在逾期租金，请及时还款'
        });
      }else if(this.isYunXu == '0'){
        this.showCalendar = false;
        Dialog.alert({
          message: '变更还款日首次还款前无法办理提前结清'
        });
      }else{
        this.showCalendar = true;
      }
    },
    onDateConfirm(date) {
      this.showCalendar = false;
      this.date = this.formatDate(date);
      this.onSubmit()
    },
    onContractConfirm(value,index) {
      this.isYuQi = '1';
      this.isYunXu = '1'
      let nearPlanDate = this.dataInfo[index].near_plandate;
      nearPlanDate = moment(nearPlanDate).format('YYYY-M-D');
      dateArr = nearPlanDate.split('-');
      // this.maxDate = new Date(dateArr[0], dateArr[1]-1, dateArr[2]);

      this.contractNum = value;
      this.showContractPicker = false;
      this.vin = this.vins[index];
      this.userName = this.dataInfo[index].cus_name;
      this.conType = this.dataInfo[index].con_type;
      var wxCode = this.dataInfo[index].wx_code;
      this.moneyValue = '';//还款金额
      this.guazhang = '';//挂账
      this.weichangValue = '';//未偿本金
      this.zuxiValue = '';//应收租息
      this.weiyuValue = '';
      this.date = '';
      this.isDisable = true;
      if(wxCode == '104'){
        this.isYuQi = '0';
        Dialog.alert({
          message: '您存在逾期租金，请及时还款'
        });
      }else if(wxCode == 'E105A'){
        this.isYunXu = '0';
      }else{
        this.maxDate = new Date(dateArr[0], dateArr[1]-1, dateArr[2]);
      }
    },
    formatDate(date, flag) {
      if (!date) return '--';
      if (flag) {
        return moment(date).format('YYYY-MM-DD HH:mm:ss');
      } else {
        return moment(date).format('YYYY-MM-DD');
      }
    },
    //格式化金额
    formatMoney(value, dot, digits){
      if(value == null){
        return '--'
      }else{
        return formatMoney(value, dot, digits)
      }
    },
    onSubmit() {
      // console.log('submit', values);
      const params = {
        access_token:this.accessToken,
        card_id:this.cardId,
        con_no:this.contractNum,
        settle_day:this.date
        // con_no:values.contractNum,
        // settle_day:values.date
      }
      calculation(params).then(res=>{
        if(res.wx_code=='0'){
          this.moneyValue = res.settle_amount;//还款金额
          this.guazhang = res.guazhang;//挂账
          this.weichangValue = this.formatMoney(res.corpus_over);//未偿本金
          this.zuxiValue = this.formatMoney(res.last_interest);//应收租息
          this.weiyuValue = this.formatMoney(res.weiyuejin);
          this.isDisable = false;
        }else{
          Toast.fail({
            message: res.msg,
            duration:'3000'
          });
          this.date = '';
          this.moneyValue = '';//还款金额
          this.guazhang = '';//挂账
          this.weichangValue = '';//未偿本金
          this.zuxiValue = '';//应收租息
          this.weiyuValue = '';
          this.isDisable = true;
        }
        
      })
    },
    applySubmit(){
      this.$store.commit(
        'calculationToApply', {
        contractNo:this.contractNum,
        moneyValue:this.moneyValue,
        jqDate:this.date
        }
      );
      this.$router.push({ path: '/earlySettlementApplication'});
    }
  },
};
</script> 
<style scoped>
.wrap{
  height: 100vh !important;
  /* padding: 20px 0px;*/
  background: #F8F8F8;
}
.van-button--default{
  border: 0px;
}
.tips{
  /* position: fixed; */
  bottom: 20px;
  color:#808080;
  font-size:13px;
  /* margin-left: 12px; */
}
.tips p{
    margin: 6px 15px;
}

</style>
<style>
.wrap .van-field__control{
  color: #666666 !important;
}
.mountWrap{
  padding-bottom: 10px;
}
.mountWrap .van-cell{
  padding: 4px 10px;
  overflow: hidden;
  color: #808080;
  font-size:15px !important;
}
</style>